var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "90%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "my-viewer-title-img" }),
        _c("div", { staticClass: "my-viewer-title" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("lang_new_document") + "-" + _vm.displayName)),
          ]),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_form"), prop: "formId" } },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.parentDeptGroupSelectList,
                      "append-to-body": "",
                      flat: "",
                      "z-index": 2999,
                      "default-expand-level": 1,
                      normalizer: _vm.menuTreeNormalizer,
                      placeholder: _vm.$t("lang_please_select"),
                    },
                    on: { select: _vm.changeParentDialogName },
                    model: {
                      value: _vm.formData.formId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "formId", $$v)
                      },
                      expression: "formData.formId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }