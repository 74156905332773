








































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { getPublishFormTree } from '@/api/form-design/FormApi';
@Component({
  name: 'DocumentForm'
})
export default class DocumentForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  @Prop({ default: false }) displayName: string;
  //   表单数据
  formData: any = {};
  //   验证规则
  formRules: Record<any, any> = {
    formId: {
      required: true,
      message: this.$t('lang_please_select'),
      trigger: 'change'
    }
  };
  // 应用下拉列表
  parentDeptGroupSelectList: any[] = [];

  created() {
    this.getFormTreeData();
  }

  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      // 关闭弹窗
      this.handleCloseDialog();
      this.$emit('success', this.formData);
    });
  }

  menuTreeNormalizer(node) {
    return {
      id: node.id,
      label: node.text,
      children: node.children,
      isDisabled: node.type == '2' ? false : true
    };
  }

  changeParentDialogName(val) {
    this.$emit('changeParentDialogName', val.text);
  }

  /**
   * 获取左侧应用树列表
   */
  getFormTreeData() {
    getPublishFormTree().then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.parentDeptGroupSelectList = [{ id: '-1', text: this.$t('lang_top_node'), children: res.data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
