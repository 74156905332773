/**
 * 获取相对于的值
 * @arr 传过来的数组
 * @name 想取的值的名字
 */
export function getValue(arr, name) {
    let value = null;
    arr.forEach(item => {
        if (item.name == name) {
            value = item.value;
        }
    })
    return value
}